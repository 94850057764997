// 收款模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {

  /**
   * @description: 应收账款查询
   * @param {string} client 客户名称
   * @param {string} dateType 日期类型
   * @param {object} dateRange 日期范围
   * @param {string} companyAlias
   * @param {string} companyName 公司
   * @param {string} customerName 科目名称
   * @param {string} email 邮箱
   * @param {boolean} update 是否更新
   * @param {string} showClientMultiple
   * @param {string} showZeroAmount 不显示发生额和余额为0
   * @param {string} version 数据版本
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/receivable/annual_statistics': (params: any, extra?: Extra) => {
    return axios(`/order/receivable/annual_statistics`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 导出应收账款年度统计
   * @return {void}
   */
  'POST/order/receivable/annual_statistics/export': (params: any, extra?: Extra) => {
    return axios(`/order/receivable/annual_statistics/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 导入应收账款
   * @return {void}
   */
  'POST/order/receivable/clients/country/batch_update': (params: any, extra?: Extra) => {
    return axios(`/order/receivable/clients/country/batch_update`, preParams(params, extra, 'post'))
  },
}
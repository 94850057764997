import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";

export default {
  /**
   * @description: 毛利查询Page
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/gross_profit/page': (params: any, extra?: Extra) => {
    return axios(`/order/gross_profit/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 毛利监测
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/gross_profit/analysis': (params: any, extra?: Extra) => {
    return axios(`/order/gross_profit/analysis`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 毛利数据初始化
   * @return {void}
   */
  'GET/order/gross_profit/init': (params: any, extra?: Extra) => {
    return axios.get<void>(`/order/gross_profit/init`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 订单收款_解绑订单
   * @return {void}
   */
  'POST/order/receipt/unbind': (params: any, extra?: Extra) => {
    return axios(`/order/receipt/unbind`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 订单收款_获取收款记录
   * @return {void}
   */
  'POST/order/receipt/payment_record': (params: any, extra?: Extra) => {
    return axios(`/order/receipt/payment_record`, preParams(params, extra, 'post'))
  
  },
  /*
   * @description: 毛利导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/gross_profit/page/export': (params: any, extra?: Extra) => {
    return axios(`/order/gross_profit/page/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 毛利监控 导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/gross_profit/analysis/export': (params: any, extra?: Extra) => {
    return axios(`/order/gross_profit/analysis/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 毛利同步订单
   * @param {array} noList
   * @return {void}
   */
  'POST/order/gross_profit/sync_order': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order/gross_profit/sync_order`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 确认关单流水列表(分页)
   * @param {array} noList
   * @return {void}
   */
  'POST/order_closed_flows/page': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order_closed_flows/page`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 导出-确认关单流水
   * @param {array} noList
   * @return {void}
   */
  'POST/order_closed_flows/export': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order_closed_flows/export`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 生成确认关单流水
   * @param {array} noList
   * @return {void}
   */
  'POST/order_closed_flows/generate': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order_closed_flows/generate`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 导出采购付款汇总明细
   * @param {array} noList
   * @return {void}
   */
  'POST/procures/export_detail': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/procures/export_detail`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 批量删除关单流水
   * @param {array} noList
   * @return {void}
   */
  'DELETE/order_closed_flows/temp_delete': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order_closed_flows/temp_delete`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: 获取上传附件
   * @param {array} noList
   * @return {void}
   */
  'POST/upload/file_info': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/upload/file_info`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 团队利润-列表
   * @param {array} noList
   * @return {void}
   */
  'POST/order_profit/dimension/org': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order_profit/dimension/org`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 订单交付_查询备注
   * @param {array} noList
   * @return {void}
   */
  'POST/order/comment/list': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order/comment/list`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 订单交付_查询备注
   * @param {array} noList
   * @return {void}
   */
  'POST/order/delivery/comment': (params: {noList: number[]}, extra?: Extra) => {
    return axios(`/order/delivery/comment`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 单据审批_分配增值税
   * @param {array} noList
   * @return {void}
   */
  'POST/house_lease_bill_details/assign_tax': (params:any, extra?: Extra) => {
    return axios(`/house_lease_bill_details/assign_tax`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 单据审批_编辑（增值税）租赁账单
   * @param {array} noList
   * @return {void}
   */
  'PATCH/house_lease_bill_details/updated_vat': (params:any, extra?: Extra) => {
    return axios(`/house_lease_bill_details/updated_vat`, preParams(params, extra, 'patch'))
  },
  /**
   * @description: 单据审批_删除租赁账单详情
   * @param {array} noList
   * @return {void}
   */
  'DELETE/house_lease_bill_details/{id}': (params: any, extra?: Extra) => {
    return axios(`/house_lease_bill_details/${params.id}`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: 确认关单流水-生成无回款勾销
   */
  'POST/order_closed_flows/generate_not_flow': (params?: any, extra?: Extra) => {
    return axios(`/order_closed_flows/generate_not_flow`, preParams(params, extra, 'post'))
  },
}

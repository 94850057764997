export default {
  'app': {
    homePage: {
      welcome: 'welcome to marshub'
    },
    'salaryDetail': {
      'tableTitle': 'salary details',
      'name': 'CN name',
      'enName': 'EN Name',
      'department': 'Department',
      'date': 'Date',
      'noData': 'no data',
      'confirmSalary': 'Confirm salary',
      'confirmPrompt': 'Whether to confirm the salary of this month:',
      'confirmCancel': 'Unconfirmed success',
      'success': 'success',
      'rate': 'Reference Rate:',
      'explain': 'explain',


      'summaryTable': 'Summary Table',
      'project': 'Project',
      'totalSalary': 'Total Salary',
      'totalAttendance': 'Total Attendance',
      'totalBonus': 'Total Bonus',
      'totalCommission': 'Total Commission',
      'otherBenefits': 'Other Benefits',
      'paymentDueMonth': 'Payment Due This Month(Gross salary)',
      'personalInsuranceTax': 'Personal Insurance&Housing&Income Tax',
      'otherDeductedFee': 'Other Need To Be Deducted Fee',
      'totalDeducted': 'Total To Be Deducted',
      'totalActualSalary': 'The Total actual salary(Include Filial Piety Allowance)',
      'payFromFinancialPart': 'Pay From Financial Part',
      'filialPiety': 'Filial Piety(Company & Personal)',
      'oneTimeReward': 'One-time reward',

      'issuedThroughPayroll': 'Issued through payroll',
      'issuedThroughOtherChannels': 'Issued through other channels',
      'summary': 'Total',
      'remark': 'Remark',
      'dividerTips': 'Details',

      'total': 'Total:',
      'salary': 'Salary',
      'basicSalary': 'Basic Salary',
      'jobAllowance': 'Job Allowance',
      'positionAllowance': 'Position Allowance',
      'otherAllowance': 'Other Allowance',
      'companyAllowance': 'Company Allowance',

      'InsuranceTax': 'Individual social insurance tax',
      'personalInsurance': 'Personal Insurance',
      'personalHousing': 'Personal Housing',
      'personalIncomeTax': 'Personal Income Tax',
      'codeWarning': 'Please enter the verification code',

    },
    'DMS':{
      'Policies & Regulations':'Policies & Regulations',
      'Sales Kit':'Sales Kit',
      'Mars Resources':'Mars Resources',
      'Download History':'Download History',
      'Recycle Bin':'Recycle Bin',
      'Trash Empty':'Trash Empty',
      'Restore':'Restore',
      'Original Location':'Original Location',
      'Date Deleted':'Date Deleted',      
      'Download':'Download',
      'Upload':'Upload',
      'Search':'Search',
      'PDF Convert':'PDF Convert',
      'New Folder':'New Folder',
      'Rename':'Rename',
      'Change Owner':'Change Owner',
      'Share':'Share',
      'Move':'Move',
      'Delete':'Delete',
      'Update':'Update',
      'Font Check':'Font Check',
      'Refresh':'Refresh',
      'Go Back':'Go Back',
      'File/Folder':'File/Folder',
      'Security Level':'Security Level',
      'Public':'Public',
      'Confidential':'Confidential',
      'Access Permission':'Access Permission',
      'All members':'All members',
      'Some members':'Some members',
      'Administrator':'Administrator',
      'Owner':'Owner',
      'Date Updated':'Date Updated',
      'General Info':'General Info',
      'Type':'Type',
      'Date Created':'Date Created',
      'Size':'Size',
      'Contains':'Contains',
      'Version':'Version',
      'History':'History',
      'Last download by':'Downloader',
      'Comments':'Comments',
      'fileUpload':{
        'File Upload':'File Upload',
        'UploadActionTip':'Drag & Drop or browse',
        'UploadTips':'Unsupported file types: compressed files, and files larger than 1GB',
        'File Name':'File Name',
        'EnterTips':'Please enter file name',
        'VersionTip':'If not using the system version number, follow company document regulations and input the correct version number.',
        'Security Level':'Security Level',
        'SecurityLevelTips':'<span>Confidential: Internal work secrets, not for disclosure without authorization.<br/>Public: Documents that can be disclosed to individuals outside the company.</span>',
        'NoteTip':'Help users understand and find documents easily.',
        'Versionplaceholder':'Enter version number as per company standards.',
        'Versionmessage':'Please enter the version number.',
        'NameLimit':'Name cannot exceed 200 characters',
        'UnSupportType':'The file type is not supported.',
        'SizeLimit':'File size limit: 1G',
        'FileChose':'Please choose a file.',
        'ChooseType':'Select file type.',
        'VersionRepeat':'Version number is duplicate with historical version.',
        'StopUpload':'Upload has been aborted.'
      },
      'File update':'File update',	
      'Alert':'Alert',
      'Cancel':'Cancel',
      'OK':'OK',
      'Folder Name':'Folder Name',
      'Folder owner is responsible for managing the directory and its contents. Please remind them to plan and manage the folder carefully.':'Folder owner is responsible for managing the directory and its contents. Please remind them to plan and manage the folder carefully.',
      'Move To':'Move To',
      'Move to here':'Move to here',
      'Version history':'Version history',
      'None':'None',
      'Files':'Files',
      'Folders':'Folders',
      'Last Modifier':'Last Modifier',
      'Preview':'Preview',
      'Notice':'Notice',
      'Watermarking':'Watermarking',
      'Document Notification':'Document Notification',
      'Document Update Summary':'Document Update Summary',
      'Notification Message':'Note: This notification will be sent to all employees via WeCom.',
      'Modify security level':'Modify security level',
      'Directory Owner':'Directory Owner',
      'ApplyRange':'Apply changes to all subdirectories and files',
      'Note':'Note',
      'DirNoteFir':'The directory owner is responsible for managing the directory and all its subdirectories and files.',
      'DirNoteFir2':'The directory owner is responsible for managing the directory and all its subdirectories and files.',
      'DirNoteSec':'Directory owner must manage their folders carefully.',
      'NewDirNoteMsg1':'Folder owner is responsible for  managing the directory and its contents. ',
      'NewDirNoteMsg2':'Please remind them to plan and manage the folder carefully.',
      'Enter folder name':'Enter folder name',
      'Choose Owner':'Choose Owner',
      'File':"File",
      'Actions':'Actions',
      'File Share':'File Share',
      'Share with link':'Share with link',
      'ShareNote':'By using the shared link to log in, others can directly access the directory where the file (folder) is located.',
      "Copy":'Copy',
      'DownLoadNote':'The document contains work secrets. Without authorization, it is not allowed to be sent out, uploaded to the internet, or shared with others.',
      'Got It':'Got It',
      'Add Watermark':'Add Watermark',
      'WatermarkNote':'Select a watermark text',
      'Add Members':'Add Members',
      'AddMenbersTips':'Check and add members on the left, or copy group emails from WeCom to batch add.',
      'Selected':'Selected',
      'MemberSelected':'Please select members.',
      'Message':{
        'FileNumLimit':'Only one file can be downloaded at a time.',
        'UpperLimit':'Exceeded the daily document download limit!',
        'WaitTip':'Please wait. If the PDF file conversion fails, you can also upload the PDF file directly or report the issue to the administrator.',
        'Conversion Successful':'Conversion successful.',
        'TypeCheck':'Please check if the file type can be converted.',
        'FileSelect':"Please select a file/folder.",
        'NameEmpty':'The name cannot be empty.',
        'Rename Successful':'Rename successful.',
        'Update Successful':'Update successful.',
        'Copy Successful':'Copy successful.',
        'FolderSelect':'Please select a folder.',
        'Move Successful':'Move successful.',
        'FileSelectOne':'Select at least one file/folder.',
        'Delete Successful':'Delete successful.',
        'SecurityLevelUpdated':'Security level updated successfully.',
        'Notification':'Notification sent to all company employees.',
        'Add successful':'Add successful.'
      },
      'DoubleClick':'Double-click to select member',
      'Username':'Username',
      'Success':'Success！',
      'FontDisabled':' is a forbidden font.',
      'FindFont':'Found the following fonts:',
      'Delete File':'Delete File',
      'DelMsgFir':"Are you sure you want to delete  everything permanently? You won't be able to recover it after deletion!",
      'DelMsgSec':"Are you sure you want to  permanently delete it? You won't be able to retrieve it afterwards!",
      'Delete Permanently':'Delete Permanently',
      'AllDel':'All items deleted successfully.',
      'Successfully restored':'Successfully restored to original location.',
      'SecurityLevelSelect':'Security level cannot be empty.',
      'Modification successful':'Modification successful.',
      'Creation successful':'Creation successful',
      'DelAlert':'Are you sure you want to move it to the recycle bin?',
      'CancelUploadingTip':'Still uploading, cancel it?',
      "TerminatedUpload":'Upload has been terminated.',
      'NewUploadTip':`Uploaded files are automatically set to 'confidential' and only the uploader can access them. To make changes, go to the file's folder.`,
      'Permissions':'Permissions',
      'Permission':'Permission',
      'Security':'Security',
      'SelOneFile':'Please choose at least one file.',
      'HasFolders':'The selected files contain folders.',
      'SettingSuccess':'Settings applied successfully.',
      'Close':'Close'
    }
  }
}
/* 薪酬模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：获取员工薪酬信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2249495
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/salary_details/page': (
      req?: IModels['POST/users/salary_details/page']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/salary_details/page',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/salary_details/page']>
    },

    /**
     * 接口名：获取薪酬发放详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2245014
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/{id}': (
      req?: IModels['POST/salaries/distributes/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/{id}']>
    },

    /**
     * 接口名：导出薪酬清单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2246726
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/export': (
      req?: IModels['POST/salaries/distributes/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/export']>
    },

    /**
     * 接口名：发放清单锁定
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2249700
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/distributes/{id}/lock': (
      req?: IModels['PUT/salaries/distributes/{id}/lock']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/lock',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/distributes/{id}/lock']>
    },

    /**
     * 接口名：发放清单解锁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2249701
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/distributes/{id}/unlock': (
      req?: IModels['PUT/salaries/distributes/{id}/unlock']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/unlock',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/distributes/{id}/unlock']>
    },

    /**
     * 接口名：社保维护界面
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2249879
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/list': (req?: IModels['POST/socials/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/list']>
    },

    /**
     * 接口名：社保信息锁定
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2250055
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/socials/lock': (req?: IModels['PATCH/socials/lock']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/lock',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/socials/lock']>
    },

    /**
     * 接口名：社保记录导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2252601
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/export': (req?: IModels['POST/socials/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/export']>
    },

    /**
     * 接口名：社保记录删除(离职且不继续缴纳)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2252696
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/socials/{id}': (req?: IModels['DELETE/socials/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/socials/{id}']>
    },

    /**
     * 接口名：薪酬项目维护(按种类划分)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2252755
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salary_categories': (req?: IModels['GET/salary_categories']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salary_categories',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salary_categories']>
    },

    /**
     * 接口名：社保记录解锁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2254928
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/socials/unlock': (req?: IModels['PATCH/socials/unlock']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/unlock',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/socials/unlock']>
    },

    /**
     * 接口名：薪酬数据导入（）批量导入）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2260149
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/salary_imports/import': (
      req?: IModels['POST/salaries/salary_imports/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/salary_imports/import']>
    },

    /**
     * 接口名：获取员工薪资详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2262443
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/salary_details/{userId}': (
      req?: IModels['GET/users/salary_details/{userId}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/salary_details/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/salary_details/{userId}']>
    },

    /**
     * 接口名：增加或者修改在其他团队的薪资
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2262444
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/entries/org': (req?: IModels['POST/salaries/entries/org']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/entries/org',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/entries/org']>
    },

    /**
     * 接口名：发放清单汇总
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2384285
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes/summary': (
      req?: IModels['GET/salaries/distributes/summary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/summary',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes/summary']>
    },

    /**
     * 接口名：批量导入基础薪资
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2269071
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_salary': (req?: IModels['POST/salaries/import_salary']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/import_salary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_salary']>
    },

    /**
     * 接口名：批量导入尽孝金
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2269073
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_filial': (req?: IModels['POST/salaries/import_filial']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/import_filial',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_filial']>
    },

    /**
     * 接口名：下载基础薪酬导入模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2269319
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/download/salary': (
      req?: IModels['GET/salaries/download/salary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/download/salary',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/download/salary']>
    },

    /**
     * 接口名：下载尽孝金模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2269321
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/download/filial': (
      req?: IModels['GET/salaries/download/filial']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/download/filial',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/download/filial']>
    },

    /**
     * 接口名：薪酬数据导入模板（批量）下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2270085
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/salary_imports/download': (
      req?: IModels['GET/salaries/salary_imports/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/salary_imports/download']>
    },

    /**
     * 接口名：薪酬数据导入模板（单个）模板下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2270086
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/import_details/download': (
      req?: IModels['GET/salaries/import_details/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/import_details/download']>
    },

    /**
     * 接口名：薪酬列表删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2270828
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/salaries/{detailId}': (req?: IModels['DELETE/salaries/{detailId}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/{detailId}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/salaries/{detailId}']>
    },

    /**
     * 接口名：获取时间内的奖金项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2278610
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/reward': (
      req?: IModels['POST/salaries/distributes/reward']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/reward',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/reward']>
    },

    /**
     * 接口名：更新薪酬发放
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2278687
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/distributes/{id}': (
      req?: IModels['PUT/salaries/distributes/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/distributes/{id}']>
    },

    /**
     * 接口名：薪酬数据修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2279242
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/salaries/salary_imports': (
      req?: IModels['PATCH/salaries/salary_imports']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/salaries/salary_imports']>
    },

    /**
     * 接口名：获取薪酬导入表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2279249
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/salary_imports/{id}': (
      req?: IModels['GET/salaries/salary_imports/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/salary_imports/{id}']>
    },

    /**
     * 接口名：（分页）获取所有薪酬导入表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2280121
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/salary_imports/page': (
      req?: IModels['GET/salaries/salary_imports/page']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/page',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/salary_imports/page']>
    },

    /**
     * 接口名：根据类别进行分组
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2281184
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salary_categories/type': (req?: IModels['GET/salary_categories/type']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salary_categories/type',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salary_categories/type']>
    },

    /**
     * 接口名：导出成本列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2282860
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/salary_details/export': (
      req?: IModels['POST/users/salary_details/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/salary_details/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/salary_details/export']>
    },

    /**
     * 接口名：获取个人，某个时间段的成本数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2292940
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/costs': (req?: IModels['POST/salaries/costs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/costs',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/costs']>
    },

    /**
     * 接口名：变更成本所属的团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2292942
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/costs/change/org': (
      req?: IModels['POST/salaries/costs/change/org']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/costs/change/org',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/costs/change/org']>
    },

    /**
     * 接口名：创建薪酬导入明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2293454
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_details': (
      req?: IModels['POST/salaries/import_details']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_details']>
    },

    /**
     * 接口名：删除薪酬导入明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2293458
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/salaries/import_details/{id}': (
      req?: IModels['DELETE/salaries/import_details/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/salaries/import_details/{id}']>
    },

    /**
     * 接口名：更新薪酬导入明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2293457
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/import_details': (req?: IModels['PUT/salaries/import_details']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/import_details',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/import_details']>
    },

    /**
     * 接口名：获取所有薪酬导入明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2293459
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/import_details': (req?: IModels['GET/salaries/import_details']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/import_details',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/import_details']>
    },

    /**
     * 接口名：完结薪资
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2294834
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/end': (req?: IModels['PUT/salaries/end']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/end',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/end']>
    },

    /**
     * 接口名：导出基础薪酬
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2316264
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/export': (req?: IModels['GET/salaries/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/export',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/export']>
    },

    /**
     * 接口名：发放清单非整数整 记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2318383
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes/{id}/check': (
      req?: IModels['GET/salaries/distributes/{id}/check']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/check',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes/{id}/check']>
    },

    /**
     * 接口名：发放清单取整
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2318385
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes/{id}/rounding': (
      req?: IModels['GET/salaries/distributes/{id}/rounding']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/rounding',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes/{id}/rounding']>
    },

    /**
     * 接口名：发放清单修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2318387
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/{id}/confirm': (
      req?: IModels['POST/salaries/distributes/{id}/confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/confirm',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/{id}/confirm']>
    },

    /**
     * 接口名：发放清单—社保公积金校对
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2319765
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes/{id}/check/security': (
      req?: IModels['GET/salaries/distributes/{id}/check/security']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/check/security',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes/{id}/check/security']>
    },

    /**
     * 接口名：月度薪酬异常明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2325037
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/month/change': (
      req?: IModels['POST/salaries/distributes/month/change']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/month/change',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/month/change']>
    },

    /**
     * 接口名：年度工资条
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2326970
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes/year_salary': (
      req?: IModels['GET/salaries/distributes/year_salary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/year_salary',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes/year_salary']>
    },

    /**
     * 接口名：月度工资
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2327806
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/monthly_salary': (
      req?: IModels['POST/salaries/distributes/monthly_salary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/monthly_salary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/monthly_salary']>
    },

    /**
     * 接口名：更新薪酬发放名称
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2328893
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/distributes/name': (
      req?: IModels['PUT/salaries/distributes/name']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/name',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/distributes/name']>
    },

    /**
     * 接口名：薪酬发放列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2334691
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes': (req?: IModels['GET/salaries/distributes']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/distributes',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes']>
    },

    /**
     * 接口名：新增发放列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2334723
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes': (req?: IModels['POST/salaries/distributes']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/distributes',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes']>
    },

    /**
     * 接口名：删除薪酬发放表行
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2334726
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/salaries/distributes/{id}': (
      req?: IModels['DELETE/salaries/distributes/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/salaries/distributes/{id}']>
    },

    /**
     * 接口名：汇总表导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2338130
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/summary/export': (
      req?: IModels['POST/salaries/distributes/summary/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/summary/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/summary/export']>
    },

    /**
     * 接口名：薪酬邮件发送
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2338140
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/mail/send': (req?: IModels['POST/salaries/mail/send']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/mail/send',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/mail/send']>
    },

    /**
     * 接口名：薪酬邮件发送情况
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2338142
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/mail/situation': (
      req?: IModels['POST/salaries/mail/situation']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/mail/situation',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/mail/situation']>
    },

    /**
     * 接口名：月度薪酬导入数据统计
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2343990
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_details/month': (
      req?: IModels['POST/salaries/import_details/month']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/month',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_details/month']>
    },

    /**
     * 接口名：下载高级导入表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2344005
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_details/download/month_import': (
      req?: IModels['POST/salaries/import_details/download/month_import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/download/month_import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_details/download/month_import']>
    },

    /**
     * 接口名：缓存至内存待确认导入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2344018
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_details/import/cache': (
      req?: IModels['POST/salaries/import_details/import/cache']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/import/cache',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_details/import/cache']>
    },

    /**
     * 接口名：导入薪酬数据预览
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2344019
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/import_details/import/preview': (
      req?: IModels['GET/salaries/import_details/import/preview']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/import/preview',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/import_details/import/preview']>
    },

    /**
     * 接口名：工资条审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2349247
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approvals/salary_approval': (
      req?: IModels['POST/approvals/salary_approval']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/approvals/salary_approval',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approvals/salary_approval']>
    },

    /**
     * 接口名：审核不通过记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2349262
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/approvals/failed_records': (
      req?: IModels['GET/approvals/failed_records']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/approvals/failed_records',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/approvals/failed_records']>
    },

    /**
     * 接口名：通知领导审核工资
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2349687
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/notify/leader': (
      req?: IModels['POST/salaries/distributes/notify/leader']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/notify/leader',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/notify/leader']>
    },

    /**
     * 接口名：获取当月汇率
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2352806
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/salaries/exchange_rate': (req?: IModels['GET/salaries/exchange_rate']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/exchange_rate',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/exchange_rate']>
    }, */

    /**
     * 接口名：汇率列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2352857
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/exchange_rate/page': (
      req?: IModels['GET/salaries/exchange_rate/page']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/exchange_rate/page',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/exchange_rate/page']>
    },

    /**
     * 接口名：修改汇率
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2352858
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/exchange_rate/update': (
      req?: IModels['POST/salaries/exchange_rate/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/exchange_rate/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/exchange_rate/update']>
    },

    /**
     * 接口名：汇总导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2356872
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/export_person': (
      req?: IModels['POST/salaries/distributes/export_person']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/export_person',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/export_person']>
    },

    /**
     * 接口名：通知员工确认工资
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2360471
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/{id}/notify/staff': (
      req?: IModels['POST/salaries/distributes/{id}/notify/staff']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/{id}/notify/staff',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/{id}/notify/staff']>
    },

    /**
     * 接口名：员工工资手动确认
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2360474
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approvals/manual_confirm': (
      req?: IModels['POST/approvals/manual_confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/approvals/manual_confirm',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approvals/manual_confirm']>
    },

    /**
     * 接口名：薪酬列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2361683
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/entries': (req?: IModels['GET/salaries/entries']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/entries',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/entries']>
    },

    /**
     * 接口名：变更成本所属的财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2366808
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/costs/change/position': (
      req?: IModels['POST/salaries/costs/change/position']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/costs/change/position',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/costs/change/position']>
    },

    /**
     * 接口名：薪酬数据导入方式
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2368796
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/import_details/import/confirm': (
      req?: IModels['PUT/salaries/import_details/import/confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/import/confirm',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/import_details/import/confirm']>
    },

    /**
     * 接口名：薪酬数据单个导入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2368807
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_details/import': (
      req?: IModels['POST/salaries/import_details/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_details/import']>
    },

    /**
     * 接口名：薪酬导入表删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2368813
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/salaries/salary_imports/{id}': (
      req?: IModels['DELETE/salaries/salary_imports/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/salaries/salary_imports/{id}']>
    },

    /**
     * 接口名：批量薪酬数据导入方式
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2368826
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/salary_imports/import/confirm': (
      req?: IModels['PUT/salaries/salary_imports/import/confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/import/confirm',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/salary_imports/import/confirm']>
    },

    /**
     * 接口名：新增薪酬导入表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2368835
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/salary_imports': (
      req?: IModels['POST/salaries/salary_imports']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/salary_imports']>
    },

    /**
     * 接口名：社保维护修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2371901
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/socials': (req?: IModels['PATCH/socials']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/socials']>
    },

    /**
     * 接口名：获取员工年度薪酬
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2375979
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/annual_salary': (
      req?: IModels['POST/salaries/distributes/annual_salary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/annual_salary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/annual_salary']>
    },

    /**
     * 接口名：发放清单详情锁定解锁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2376566
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/distributes/detail/locked': (
      req?: IModels['PUT/salaries/distributes/detail/locked']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/detail/locked',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/distributes/detail/locked']>
    },

    /**
     * 接口名：薪酬详情部分锁定
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2377129
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/distributes/detail/batch_locked': (
      req?: IModels['PUT/salaries/distributes/detail/batch_locked']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/detail/batch_locked',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/distributes/detail/batch_locked']>
    },

    /**
     * 接口名：成本清单校对
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2378526
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salary/check/cost': (req?: IModels['POST/salary/check/cost']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salary/check/cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salary/check/cost']>
    },

    /**
     * 接口名：薪酬导入数据重复校验2.0版本（单个）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2379412
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/import_details/import/check': (
      req?: IModels['POST/salaries/import_details/import/check']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/import_details/import/check',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/import_details/import/check']>
    },

    /**
     * 接口名：薪酬导入数据重复校验2.0版本（批量）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2379423
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/salary_imports/import/check': (
      req?: IModels['POST/salaries/salary_imports/import/check']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/import/check',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/salary_imports/import/check']>
    },

    /**
     * 接口名：获取-对比人员列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380424
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/social_security_bureau_datas/user': (
      req?: IModels['POST/social_security_bureau_datas/user']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/user',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/social_security_bureau_datas/user']>
    },

    /**
     * 接口名：按种类获取人员对比社保数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380426
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/social_security_bureau_datas/compare': (
      req?: IModels['GET/social_security_bureau_datas/compare']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/compare',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/social_security_bureau_datas/compare']>
    },

    /**
     * 接口名：社保局数据上传记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380428
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/social_security_bureau_datas/upload_log': (
      req?: IModels['POST/social_security_bureau_datas/upload_log']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/upload_log',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/social_security_bureau_datas/upload_log']>
    },

    /**
     * 接口名：下载上传的社保文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380429
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/social_security_bureau_datas/download': (
      req?: IModels['GET/social_security_bureau_datas/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/social_security_bureau_datas/download']>
    },

    /**
     * 接口名：导入社保局数据(上传)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2379986
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/social_security_bureau_datas/upload': (
      req?: IModels['POST/social_security_bureau_datas/upload']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/social_security_bureau_datas/upload']>
    },

    /**
     * 接口名：勾销社保
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380716
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/social_security_bureau_datas/tick': (
      req?: IModels['PATCH/social_security_bureau_datas/tick']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/tick',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/social_security_bureau_datas/tick']>
    },

    /**
     * 接口名：锁定/解锁社保
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380717
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/social_security_bureau_datas/locked': (
      req?: IModels['PATCH/social_security_bureau_datas/locked']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/locked',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/social_security_bureau_datas/locked']>
    },

    /**
     * 接口名：删除(物理删除/逻辑删除)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2380718
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/social_security_bureau_datas/{id}': (
      req?: IModels['DELETE/social_security_bureau_datas/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/social_security_bureau_datas/{id}']>
    },

    /**
     * 接口名：年度社保校验
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2385203
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/social_security_bureau_datas/check': (
      req?: IModels['POST/social_security_bureau_datas/check']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/check',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/social_security_bureau_datas/check']>
    },

    /**
     * 接口名：基础薪酬差异
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2385997
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salary/check/base_salary': (
      req?: IModels['POST/salary/check/base_salary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salary/check/base_salary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salary/check/base_salary']>
    },

    /**
     * 接口名：删除社保局附件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2387653
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/social_security_bureau_datas/file/{id}': (
      req?: IModels['DELETE/social_security_bureau_datas/file/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/file/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/social_security_bureau_datas/file/{id}']>
    },

    /**
     * 接口名：修改缴费明细上传记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2390601
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/social_security_bureau_datas/update': (
      req?: IModels['PATCH/social_security_bureau_datas/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/update',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/social_security_bureau_datas/update']>
    },

    /**
     * 接口名：生成个人成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2395619
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/salary_imports/create/person_cost': (
      req?: IModels['PUT/salaries/salary_imports/create/person_cost']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/salary_imports/create/person_cost',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/salary_imports/create/person_cost']>
    },

    /**
     * 接口名：差异记录查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2397051
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salary/check/records/page': (
      req?: IModels['POST/salary/check/records/page']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salary/check/records/page',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salary/check/records/page']>
    },

    /**
     * 接口名：新增差异记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2397053
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salary/check/records': (req?: IModels['POST/salary/check/records']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salary/check/records',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salary/check/records']>
    },

    /**
     * 接口名：差异记录删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2397052
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salary/check/record/{id}': (
      req?: IModels['PUT/salary/check/record/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salary/check/record/{id}',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salary/check/record/{id}']>
    },

    /**
     * 接口名：社保缴费明细(上传记录批量下载)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2406957
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/social_security_bureau_datas/batch_download': (
      req?: IModels['POST/social_security_bureau_datas/batch_download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/batch_download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/social_security_bureau_datas/batch_download']>
    },

    /**
     * 接口名：导出社保缴费记录上传明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2414659
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/social_security_bureau_datas/export': (
      req?: IModels['POST/social_security_bureau_datas/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/social_security_bureau_datas/export']>
    },

    /**
     * 接口名：生成报销单记录
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/social_security_bureau_datas/generate_voucher': (
      req?: IModels['GET/social_security_bureau_datas/generate_voucher']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/social_security_bureau_datas/generate_voucher',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/social_security_bureau_datas/generate_voucher']>
    },

    /**
     * 接口名：管理员验证
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507238&itf=2427722
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/isAdmin': (req?: IModels['GET/isAdmin']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/isAdmin',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/isAdmin']>
    },

    // 工资发放清单

    /**
 * 接口名：工资汇总清单
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380105
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'GET/payroll/summary/query_summary': (
      req?: IModels['GET/payroll/summary/query_summary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/summary/query_summary',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/payroll/summary/query_summary']>
    },

    /**
     * 接口名：生成工资发放清单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380107
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/summary/generate': (
      req?: IModels['POST/payroll/summary/generate']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/summary/generate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/summary/generate']>
    },

    /**
     * 接口名：工资发放清单_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380190
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/detail/query': (req?: IModels['POST/payroll/detail/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/payroll/detail/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/detail/query']>
    },

    /**
     * 接口名：获取发放行列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380581
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/payroll/detail/bank_drop_list': (
      req?: IModels['GET/payroll/detail/bank_drop_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/detail/bank_drop_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/payroll/detail/bank_drop_list']>
    },

    /**
     * 接口名：获取bank列表下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380621
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/banks/drop_list': (req?: IModels['GET/banks/drop_list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/banks/drop_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/banks/drop_list']>
    },

    /**
     * 接口名：工资发放清单_批量修改发放行
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380572
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/payroll/detail/update_pay_bank': (
      req?: IModels['PUT/payroll/detail/update_pay_bank']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/detail/update_pay_bank',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/payroll/detail/update_pay_bank']>
    },

    /**
     * 接口名：工资发放清单_批量_锁定或解锁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380680
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/payroll/detail/batch_locked': (
      req?: IModels['PUT/payroll/detail/batch_locked']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/detail/batch_locked',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/payroll/detail/batch_locked']>
    },

    /**
     * 接口名：工资发放清单_统计
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2380864
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/detail/query_summary': (
      req?: IModels['POST/payroll/detail/query_summary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/detail/query_summary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/detail/query_summary']>
    },

    /**
     * 接口名：工资发放清单生成记录_解锁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2381006
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/payroll/detail_record/locked': (
      req?: IModels['PUT/payroll/detail_record/locked']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/detail_record/locked',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/payroll/detail_record/locked']>
    },

    /**
     * 接口名：工资发放清单导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2381904
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/detail/export': (req?: IModels['POST/payroll/detail/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/payroll/detail/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/detail/export']>
    },

    /**
     * 接口名：工资发放清单_批量删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2382095
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/payroll/detail': (req?: IModels['DELETE/payroll/detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/payroll/detail',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/payroll/detail']>
    },

    /**
     * 接口名：工资发放清单_生成支付单据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2384948
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/generate_pay_bill': (
      req?: IModels['POST/payroll/generate_pay_bill']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/generate_pay_bill',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/generate_pay_bill']>
    },

    /**
     * 接口名：工资发放清单_重新生成发放清单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2386910
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/repayments': (req?: IModels['POST/payroll/repayments']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/payroll/repayments',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/repayments']>
    },

    /**
     * 接口名：工资发放清单_获取银行卡列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2387200
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/payroll/get_user_bank': (req?: IModels['GET/payroll/get_user_bank']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/payroll/get_user_bank',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/payroll/get_user_bank']>
    },

    /**
     * 接口名：工资发放清单_更新工资发放清单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2387686
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/payroll/renew': (req?: IModels['PATCH/payroll/renew']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/payroll/renew',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/payroll/renew']>
    },

    /**
     * 接口名：工资发放清单_多批统计
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2388043
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/summary/query_summaries': (
      req?: IModels['POST/payroll/summary/query_summaries']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/summary/query_summaries',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/summary/query_summaries']>
    },

    /**
     * 接口名：工资发放清单_更新银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2388244
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/payroll/renew_bank_info': (
      req?: IModels['PATCH/payroll/renew_bank_info']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/renew_bank_info',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/payroll/renew_bank_info']>
    },

    /**
     * 接口名：导出未生成工资发放清单人员名单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=531610&itf=2389274
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/payroll/summary/missing': (
      req?: IModels['POST/payroll/summary/missing']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/payroll/summary/missing',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/payroll/summary/missing']>
    },
    /**
     * 接口名：offer转至薪酬列表
     */
    'POST/offer_approvals/list': (req?: IModels['POST/offer_approvals/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/offer_approvals/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/offer_approvals/list']>
    },
    /**
     * 接口名：关联员工
     */
    'PATCH/offer_approvals/bind': (req?: IModels['PATCH/offer_approvals/bind']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/offer_approvals/bind',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/offer_approvals/bind']>
    },
  }
}
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 创建节假日方案
   * @param {string} startDate 开始日期
   * @param {string} endDate 结束日期
   * @param {string} description 备注
   * @param {array} holidaySchemes 
   * @return {array}
   */
  'POST/checkin_holidays': (params: any, extra: Extra) => {
    return axios(`/checkin_holidays`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改节假日方案
   * @param {string} startDate 开始日期
   * @param {string} endDate 结束日期
   * @param {string} description 备注
   * @param {array} holidaySchemes 
   * @return {array}
   */
  'PATCH/checkin_holidays': (params: any, extra: Extra) => {
    return axios(`/checkin_holidays`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 查询节假日方案
   * @param {number} page
   * @param {number} size
   * @return {array}
   */
  'POST/checkin_holidays/list': (params: {page: number, size: number}, extra: Extra) => {
    return axios(`/checkin_holidays/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取可加班换薪记录
   * @return {array}
   */
  'GET/checkin_balances/overtime_salary': () => axios.get<any[]>(`/checkin_balances/overtime_salary`),

  /**
   * @description: 加班换薪记录附件预览
   * @return {array}
   */
  'GET/checkin_balances/view_overtime_file': (params: any, extra: Extra) => {
    return axios.get<any>(`/checkin_balances/view_overtime_file`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 假期余额-获取单个详情
   * @return {array}
   */
  'GET/checkin_approvals/id': (params: any, extra: Extra) => {
    return axios.get<any>(`/checkin_approvals/${params.id}`, preParams({}, extra, 'get'))
  },

  /**
   * @description: 根据用户获取考勤信息
   * @param {number} userId
   * @param {string} date 月份
   * @return {array}
   */
  'POST/checkin': (params: {date: string, userId: number}, extra: Extra) => {
    return axios(`/checkin`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 个人扣款详情
   * @param {number} userId
   * @param {string} date 月份
   * @return {array}
   */
  'POST/checkin/individual/deduct_money': (params: {date: string, userId: number}, extra: Extra) => {
    return axios(`/checkin/individual/deduct_money`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 确认考勤（个人）
   * @param {number} userId
   * @param {string} date 月份
   * @param {string} code 验证码
   * @return {array}
   */
  'PATCH/checkin/user_verify': (params: {code: string, userId: number, date: string}, extra: Extra) => {
    return axios(`/checkin/user_verify`, preParams(params, extra, 'patch'))
  },
  
  /**
   * @description: 考勤详情
   * @param {number} page
   * @param {number} size
   * @param {string} date 月份
   * @return {array}
   */
  'POST/checkin/owner_detail': (params: {date: string, page: number, size: number}, extra: Extra) => {
    return axios(`/checkin/owner_detail`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 打卡规则列表
   * @return {array}
   */
  'GET/checkin_punch_rules': () => axios.get<any>(`/checkin_punch_rules`),
    
  /**
   * @description: 删除打卡规则
   * @param {number} id
   * @return {array}
   */
  'DELETE/checkin_punch_rules/{id}': ({id}: {id: number}) => axios.delete<void>(`/checkin_punch_rules/${id}`),
    
  /**
   * @description: 创建打卡规则
   * @param {string} name
   * @param {string} morningStart 早上打卡时间起
   * @param {string} morningEnd 止
   * @param {string} nonoStart 中午休息时间起
   * @param {string} nonoEnd 止
   * @param {string} afternoonStart 下午打卡时间起
   * @param {string} afternoonEnd 止
   * @param {string} place 地点
   * @param {string} scope 作用域
   * @param {number} freedom 自由度
   * @return {array}
   */
  'POST/checkin_punch_rules': (params: {name: string}, extra: Extra) => {
    return axios(`/checkin_punch_rules`, preParams(params, extra, 'post'))
  },
    
  /**
   * @description: 编辑打卡规则
   * @param {string} name
   * @param {string} morningStart 早上打卡时间起
   * @param {string} morningEnd 止
   * @param {string} nonoStart 中午休息时间起
   * @param {string} nonoEnd 止
   * @param {string} afternoonStart 下午打卡时间起
   * @param {string} afternoonEnd 止
   * @param {string} place 地点
   * @param {string} scope 作用域
   * @param {number} freedom 自由度
   * @param {number} id
   * @return {array}
   */
  'PATCH/checkin_punch_rules': (params: {name: string, id: number}, extra: Extra) => {
    return axios(`/checkin_punch_rules`, preParams(params, extra, 'patch'))
  },
      
  /**
   * @description: 考勤异常报表
   * @param {object} dateRange
   * @param {number} orgId 
   * @param {number} companyIds 
   * @return {array}
   */
  'POST/checkin_approvals/report': (params: any, extra: Extra) => {
    return axios(`/checkin_approvals/report`, preParams(params, extra, 'post'))
  },
}